<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    this.$rtm.setBase(this)
    this.updateMobileStatus()
    window.addEventListener('resize', this.updateMobileStatus)
  },
  methods: {
    updateMobileStatus() {
      this.$rtm.store.isMobile = window.matchMedia('(max-width: 800px)').matches
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

body {
  margin: 0px;
  padding: 0px;
}
html {
  font-size: 10px;
}

.pc-content {
  display: block;
}
.mobile-content {
  display: none;
}
</style>
<style lang="less">
.site-gray {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: url('data:image/svg+xml;utf8,#grayscale');
  filter: progid:DXImageTransform.Microsoft.BasicImage(Grayscale=1);
  -webkit-filter: grayscale(1);
}
html,
body {
  font-size: 10px;
}
.desktop-present {
  display: block !important;
}
.mobile-present {
  display: none !important;
}
@media all and (max-width: 801px) {
  html,
  body {
    font-size: 6px !important;
  }
  .desktop-present {
    display: none !important;
  }

  .mobile-present {
    display: block !important;
  }
}
@media all and (max-width: 1200px) and (min-width: 800px) {
  html,
  body {
    font-size: calc(100vw / 125) !important;
  }
  .desktop-present {
    display: block !important;
  }

  .mobile-present {
    display: none !important;
  }
}
</style>
