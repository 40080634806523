import RD from "@/api/RD";
export default {
  store: {
    webName: "视觉江苏",
    count: 1,
    addrTree: [],
    typeTree: [],
    userInfo: {},
    logined: false,
    isMobile: false,
    loginStatus: false, // 登录状态
    logoedShow: false, // 更多的打开登录弹框
    logoedFixtopCatalog: ''
  },

  /**
   * in this method, this is the basicFrame, vue component
   */
  initExtra() {
    console.log("init extra run");
    let thiz = this;
    var prefixURL = "https://www.vijs.net/client/v_0.1";
    RD.setPreFix(prefixURL);
    RD.setErrorHandler(function (error) {
      console.log('rtmxiuxiu', error);
      return new Promise((resolve, reject) => {
        if (error.code == -11) {
          thiz.$alert(error.msg, {
            confirmButtonText: '确定',
            type: 'warning',
          })
            .then(() => {
              console.log('sure');
              thiz.$router.replace('/picture.html')
            })
            .catch(() => { })
          resolve();
        } else if (error.code && error.code < 10) {
          thiz.$message(error.msg);
          thiz.$rtm.hideLoading();
          resolve();
        } else {
          reject();
        }
      });
    });
    RD.setLoadingStartAction(function () {
      console.log("showloading");
      thiz.$rtm.showLoading();
    });
    RD.setLoadingEndAction(function () {
      console.log("hideLoading");
      thiz.$rtm.hideLoading();
    });
  }
};
